/**
 * Handle the qualified field case:
 * - determines which qualifier is selected and delegates to that handler
 */
function QualifiedDelegateCriteriaHandler(fn, qc, ch) {
    this.fieldName = fn;
    this.qualifierSelector = 'li.qualifiers.' + qc;
    this.criteriaHandlers = ch;
}
QualifiedDelegateCriteriaHandler.prototype.showCriteria = function (form) {
    var qualifier = form.find(this.qualifierSelector + ' option:selected').first();
    if (!qualifier) {
        qualifier = form.find(this.qualifierSelector + ' option').first();
        qualifier.attr('selected', 'selected');
    }
    this.criteriaHandlers[qualifier.val()].showCriteria(form);
};
QualifiedDelegateCriteriaHandler.prototype.getCriteria = function (form) {
    var qualifier = form.find(this.qualifierSelector + ' option:selected').first();
    if (!qualifier) {
        qualifier = form.find(this.qualifierSelector + ' option').first();
        qualifier.attr('selected', 'selected');
    }
    return this.criteriaHandlers[qualifier.val()].getCriteria(form);
};
QualifiedDelegateCriteriaHandler.prototype.setCriteria = function (form, criteria) {
    this.criteriaHandlers[criteria.fieldPropertyName].setCriteria(form, criteria);
};
QualifiedDelegateCriteriaHandler.prototype.reset = function (form) {
    var field = form.find('li.fields option').filterByValue(this.fieldName);
    field.attr('selected', 'selected');
    var qualifier = form.find(this.qualifierSelector + ' option:selected').first();
    this.criteriaHandlers[qualifier.val()].reset(form);
};
QualifiedDelegateCriteriaHandler.prototype.validate = function (form, errors) {
    var qualifier = form.find(this.qualifierSelector + ' option:selected').first();
    this.criteriaHandlers[qualifier.val()].validate(form, errors);
};

/**
 * Handle the qualified case of:
 * - attribute defines the the qualifier
 * - qualifier defines the query field
 * - there is a single operator
 * - there is a single value
 */
function QualifiedCriteriaHandler(fName, qName, qClass, oClass, vStrategy) {
    this.fieldName = fName;
    this.qualifierName = qName;
    this.qualifierSelector = 'li.qualifiers.' + qClass;
    this.operatorSelector = 'li.operators.' + oClass;
    this.valueStrategy = vStrategy;
}
QualifiedCriteriaHandler.prototype.showCriteria = function (form) {
    form.find(this.qualifierSelector).show();
    form.find(this.operatorSelector).show();
    this.valueStrategy.show(form);
};
QualifiedCriteriaHandler.prototype.getCriteria = function (form) {
    var qualifier = form.find(this.qualifierSelector + ' option').filterByValue(this.qualifierName).first();
    var operator = form.find(this.operatorSelector + ' option:selected').first();
    var propertyDisplayName = form.find(this.qualifierSelector + ' label').filterByAttribute('for', 'displayValueFormat').text().format(qualifier.text());
    var value = this.valueStrategy.get(form);
    return new QueryCriteria({
        fieldDisplayName: propertyDisplayName,
        fieldPropertyName: qualifier.val(),
        operatorDisplayValue: operator.text() || operator.val(),
        operator: operator.val(),
        valueDisplayName: value['text'],
        valueSearchValue: value['val'],
        restricted: value['restricted']
    });
};
QualifiedCriteriaHandler.prototype.setCriteria = function (form, criteria) {
    form.find('li.fields option').filterByValue(this.fieldName).first().attr('selected', 'selected');
    this.qualifierSelect = form.find(this.qualifierSelector + ' select').first();
    if (this.qualifierSelect.hasClass('adhoc-select-2')) {
        if (this.qualifierSelect.hasClass('select2-hidden-accessible')) {
            // if the select already has select2 on it, don't reinitialize it.
            // if we reinitialize it we lose the options that were passed in before.
            this.qualifierSelect.val(this.qualifierName).trigger('change');
        } else {
            this.qualifierSelect.select2().val(this.qualifierName).trigger('change');
        }
    } else {
        form.find(this.qualifierSelector + ' option').filterByValue(this.qualifierName).first().attr('selected', 'selected');
    }
    form.find(this.operatorSelector + ' option').filterByValue(criteria.operator).first().attr('selected', 'selected');
    var value = new Array();
    value['text'] = criteria.valueDisplayName;
    value['val'] = criteria.valueSearchValue;
    value['restricted'] = criteria.restricted;
    this.valueStrategy.set(form, value);
};
QualifiedCriteriaHandler.prototype.reset = function (form) {
    form.find('li.fields option').filterByValue(this.fieldName).first().attr('selected', 'selected');
    form.find(this.qualifierSelector + ' option').filterByValue(this.qualifierName).first().attr('selected', 'selected');
    form.find(this.operatorSelector + ' option').first().attr('selected', 'selected');
    this.valueStrategy.reset(form);
};
QualifiedCriteriaHandler.prototype.validate = function (form, errors) {
    var qualifier = form.find(this.qualifierSelector + ' option:selected').first();
    if ($.trim(qualifier.val()) == '') {
        errors.add(form.find(this.qualifierSelector + ' label').filterByAttribute('for', 'displayValueFormat').text());
    }
    this.valueStrategy.validate(form, errors);
};

/**
 * Handle the simple case of:
 * - attribute defines the query field
 * - there is a single operator
 * - there is a single value
 */
function SimpleCriteriaHandler(fName, oClass, vStrategy) {
    this.fieldName = fName;
    this.operatorSelector = 'li.operators.' + oClass;
    this.valueStrategy = vStrategy;
}
SimpleCriteriaHandler.prototype.showCriteria = function (form) {
    form.find(this.operatorSelector).show();
    this.valueStrategy.show(form);
};
SimpleCriteriaHandler.prototype.getCriteria = function (form) {
    var field = form.find('li.fields option').filterByValue(this.fieldName).first();
    var operator = form.find(this.operatorSelector + ' option:selected').first();
    var value = this.valueStrategy.get(form);
    return new QueryCriteria({
        fieldDisplayName: field.text(),
        fieldPropertyName: (value['isParent'] === true ? field.attr('parent-value') : field.val()),
        operatorDisplayValue: operator.text() || operator.val(),
        operator: operator.val(),
        valueDisplayName: value['text'],
        valueSearchValue: value['val'],
        restricted: value['restricted']
    });
};
SimpleCriteriaHandler.prototype.setCriteria = function (form, criteria) {
    form.find('li.fields option').filterByValue(this.fieldName).first().attr('selected', 'selected');
    form.find(this.operatorSelector + ' option').filterByValue(criteria.operator).first().attr('selected', 'selected');
    var value = new Array();
    value['text'] = criteria.valueDisplayName;
    value['val'] = criteria.valueSearchValue;
    value['restricted'] = criteria.restricted;
    this.valueStrategy.set(form, value);
};
SimpleCriteriaHandler.prototype.reset = function (form) {
    form.find('li.fields option').filterByValue(this.fieldName).attr('selected', 'selected');
    form.find(this.operatorSelector + ' option').first().attr('selected', 'selected');
    this.valueStrategy.reset(form);
};
SimpleCriteriaHandler.prototype.validate = function (form, errors) {
    this.valueStrategy.validate(form, errors);
};

/**
 * Handle the simple case of:
 * - attribute defines the query field
 * - there are 2 operators
 * - there are 2 values
 * ---- This may turn out to be multiple operators and values. My use case is only 2
 */
function SimpleMultipleCriteriaHandler(fName, ch, sqValue) {
    this.fieldName = fName;
    this.criteriaHandlers = ch;
    this.subqueryValue = sqValue;
}
SimpleMultipleCriteriaHandler.prototype.showCriteria = function (form) {
    for (var i = 0; i < this.criteriaHandlers.length; i++) {
        form.find(this.criteriaHandlers[i].operatorSelector).show();
        this.criteriaHandlers[i].valueStrategy.show(form);
        var operator = form.find(this.criteriaHandlers[i].operatorSelector + ' option:selected').first().val();
        if ($.inArray(operator, this.criteriaHandlers[i].singleOps) > -1) {
            break;
        }
    }
};
SimpleMultipleCriteriaHandler.prototype.getCriteria = function (form) {
    var field = form.find('li.fields option').filterByValue(this.fieldName).first();
    var queryCriteria;
    var value;
    var mainOperator = form.find(this.criteriaHandlers[0].operatorSelector + ' option:selected').first().val();
    var mainText = form.find(this.criteriaHandlers[0].operatorSelector + ' option:selected').first().text();
    if ($.inArray(mainOperator, this.criteriaHandlers[0].singleOps) > -1) {
        queryCriteria = new QueryCriteria({
            fieldDisplayName: field.text(),
            fieldPropertyName: field.val(),
            operator: mainOperator,
            valueSearchValue: this.subqueryValue,
            valueDisplayName: "",
            restricted: false
        });
    } else {
        if (mainOperator === "is not") {
            queryCriteria = new QueryCriteria({
                fieldDisplayName: field.text(),
                fieldPropertyName: field.val(),
                operator: "not in subquery",
                operatorDisplayValue: "",
                valueSearchValue: this.subqueryValue,
                valueDisplayName: "",
                restricted: false
            });
        } else {
            queryCriteria = new QueryCriteria({
                fieldDisplayName: field.text(),
                fieldPropertyName: field.val(),
                operator: "in subquery",
                operatorDisplayValue: "",
                valueSearchValue: this.subqueryValue,
                valueDisplayName: "",
                restricted: false
            });
        }
        var subquery = new QueryCriteria({
            operator: "and",
            operatorDisplayValue: "",
            fieldDisplayName: "",
            restricted: false,
            inSubquery: true,
            primarySubquery: true
        });
        for (var i = 0; i < this.criteriaHandlers.length; i++) {
            var operator = form.find(this.criteriaHandlers[i].operatorSelector + ' option:selected').first();
            value = this.criteriaHandlers[i].valueStrategy.get(form);
            var subs = this.createSubqueries(mainText, operator, value, this.criteriaHandlers[i]);
            for (var j = 0; j < subs.length; j++) {
                subquery.addSubquery(subs[j]);
            }
            if (operator.val() === 'exists' || operator.val() === 'does not exist') {
                break;
            }
        }
        queryCriteria.addValueSubquery(subquery);
    }
    return queryCriteria;
};
SimpleMultipleCriteriaHandler.prototype.createSubqueries = function (mainText, operator, value, criteriaHandler) {
    var subqueries = [];
    switch (operator.val()) {
        // is not gets translated into "not in subquery" so we change it to "is" for the subquery.
        case "is not" :
            subqueries.push(new QueryCriteria({
                fieldPropertyName: (value['isParent'] ? criteriaHandler.parentFieldName : criteriaHandler.fieldName),
                fieldDisplayName: "",
                operatorDisplayValue: operator.val(),
                operator: "is",
                valueDisplayName: value['text'],
                valueSearchValue: value['val'],
                restricted: value['restricted'],
                inSubquery: true
            }));
            break;
        case "lifespan after or equals" :
        case "lifespan after" :
            subqueries.push(new QueryCriteria({
                startFieldPropertyName: criteriaHandler.fieldName,
                endFieldPropertyName: criteriaHandler.alternateFieldName,
                fieldPropertyName: criteriaHandler.alternateFieldName,
                fieldDisplayName: "",
                operatorDisplayValue: operator.text(),
                operator: operator.val(),
                valueDisplayName: value['text'],
                valueSearchValue: value['val'],
                restricted: value['restricted'],
                inSubquery: true
            }));
            break;
        case "lifespan within" :
        case "lifespan within last" :
        case "lifespan within next" :
            if(mainText === 'becomes') {
                subqueries.push(new QueryCriteria({
                    startFieldPropertyName: criteriaHandler.fieldName,
                    endFieldPropertyName: criteriaHandler.alternateFieldName,
                    fieldPropertyName: (value['isParent'] ? criteriaHandler.parentFieldName : criteriaHandler.fieldName),
                    fieldDisplayName: "",
                    operator: operator.val().replace('lifespan ', ''),
                    operatorDisplayValue: operator.text(),
                    valueDisplayName: value['text'],
                    valueSearchValue: value['val'],
                    restricted: value['restricted'],
                    inSubquery: true
                }));
            } else {
                subqueries.push(new QueryCriteria({
                    fieldDisplayName: "",
                    startFieldPropertyName: criteriaHandler.fieldName,
                    endFieldPropertyName: criteriaHandler.alternateFieldName,
                    operator: operator.val(),
                    valueDisplayName: value['text'],
                    valueSearchValue: value['val'],
                    restricted: value['restricted'],
                    inSubquery: true
                }));
            }
            break;
        case "lifespan equals" :
            subqueries.push(new QueryCriteria({
                startFieldPropertyName: criteriaHandler.fieldName,
                endFieldPropertyName: criteriaHandler.alternateFieldName,
                fieldDisplayName: "",
                operatorDisplayValue: operator.text(),
                operator: operator.val(),
                valueDisplayName: value['text'],
                valueSearchValue: value['val'],
                restricted: value['restricted'],
                inSubquery: true
            }));
            break;
        default :
            subqueries.push(new QueryCriteria({
                startFieldPropertyName: criteriaHandler.fieldName,
                endFieldPropertyName: criteriaHandler.alternateFieldName,
                fieldPropertyName: (value['isParent'] ? criteriaHandler.parentFieldName : criteriaHandler.fieldName),
                fieldDisplayName: "",
                operator: operator.val(),
                operatorDisplayValue: operator.text(),
                valueDisplayName: value['text'],
                valueSearchValue: value['val'],
                restricted: value['restricted'],
                inSubquery: true
            }));
            break;
    }
    return subqueries;
};
SimpleMultipleCriteriaHandler.prototype.setCriteria = function (form, criteria) {
    form.find('li.fields option').filterByValue(this.fieldName).first().attr('selected', 'selected');
    var value = [];
    if (criteria.valueSubqueries.length > 0) {
        for (var i = 0; i < this.criteriaHandlers.length && i < criteria.valueSubqueries.length; i++) {
            form.find(this.criteriaHandlers[i].operatorSelector + ' option')
                .filter(function () {
                    return $(this).text() === criteria.valueSubqueries[i].operatorDisplayValue;
                })
                .first()
                .attr('selected', 'selected');
            value['text'] = criteria.valueSubqueries[i].valueDisplayName;
            value['val'] = criteria.valueSubqueries[i].valueSearchValue;
            this.criteriaHandlers[i].valueStrategy.set(form, value);
        }
    } else {
        form.find(this.criteriaHandlers[0].operatorSelector + ' option').filterByValue(criteria.operator).first().attr('selected', 'selected');
        value['text'] = criteria.valueDisplayName;
        value['val'] = criteria.valueSearchValue;
        this.criteriaHandlers[0].valueStrategy.set(form, value);
    }
};
SimpleMultipleCriteriaHandler.prototype.reset = function (form) {
    var option;
    form.find('li.fields option').filterByValue(this.fieldName).attr('selected', 'selected');
    for (var i = 0; i < this.criteriaHandlers.length; i++) {
        form.find(this.criteriaHandlers[i].operatorSelector + ' option').first().attr('selected', 'selected');
        this.criteriaHandlers[i].valueStrategy.reset(form);
    }
};
SimpleMultipleCriteriaHandler.prototype.validate = function (form, errors) {
    var i, operator;
    for (i = 0; i < this.criteriaHandlers.length; i++) {
        this.criteriaHandlers[i].validate(form, errors);
        operator = form.find(this.criteriaHandlers[i].operatorSelector + ' option:selected').first().val();
        if ($.inArray(operator, this.criteriaHandlers[i].singleOps) > -1) {
            break;
        }
    }
};