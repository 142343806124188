/**
 * Criteria data holder
 */
function QueryCriteria(obj) {
    this.fieldDisplayName = obj.fieldDisplayName;
    this.fieldPropertyName = obj.fieldPropertyName;
    this.operatorDisplayValue = obj.operatorDisplayValue === undefined ? obj.operator : obj.operatorDisplayValue;
    this.operator = obj.operator;
    this.restricted = obj.restricted;
    this.valueDisplayName = obj.valueDisplayName;
    this.valueSearchValue = obj.valueSearchValue;
    this.valueSubqueries = [];
    this.subqueries = [];
    this.startFieldPropertyName = obj.startFieldPropertyName;
    this.endFieldPropertyName = obj.endFieldPropertyName;
    this.inSubquery = obj.inSubquery || false;
    this.primarySubquery = obj.primarySubquery || false;
    this.isParent = obj.isParent;
}

QueryCriteria.prototype.addValueSubquery = function(sq) {
    this.valueSubqueries.push(sq);
};

QueryCriteria.prototype.addSubquery = function(q) {
    this.subqueries.push(q);
};

/**
 * Function to format organization data list item options
 */
function formatDataListOptions(form) {
    var $select = $('#' + form);
    var i;
    var options = $select.find("option");
    for(i=0;i<options.length;i++) {
        var $option = $(options.get(i));
        var display;
        try{
            display = $.parseJSON($option.text());
        } catch (error) {
            display = {
                isParent: false,
                displayValue: $option.text()
            }
        }
        if(display['isParent'] === true) {
            $option.addClass("is-parent");
        } else {
            $option.addClass("is-child");
        }
        $option.text(display['displayValue']);
    }
    $select.select2({
        dropdownCssClass: "parent-label-group",
        width: '100%',
        templateResult: function(data) {
            if(!data.element){
                return data.text;
            }

            var option = $(data.element);

            var wrapper = $('<span></span>');
            wrapper.addClass(option[0].className);
            wrapper.text(data.text);

            return wrapper;
        },
        templateSelection: function(data) {
            if(!data.element){
                return data.text;
            }

            var option = $(data.element);

            var wrapper = $('<span></span>');
            wrapper.addClass(option[0].className);
            wrapper.text(data.text);

            return wrapper;
        }
    });
    $select.addClass('adhoc-select-2');
}

/**
 * Controller for the advanced search logic.
 */
function QueryBuilderController(form) {
  this.criteriaHandlers = new Array();
  this.form = form;
}
QueryBuilderController.prototype.registerCriteriaHandler = function(fn, h) {
  this.criteriaHandlers[fn] = h;
};
QueryBuilderController.prototype.registerQualifiedDelegateCriteriaHandler = function(fName, qClass) {
  this.registerCriteriaHandler(fName, new QualifiedDelegateCriteriaHandler(fName, qClass, this.criteriaHandlers));
};
QueryBuilderController.prototype.registerQualifiedAutoCompleteListCriteriaHandler = function(fName, qName, qClass, oClass, vClass, vClassIn) {
  var strategy = new OperatorDelegateStrategy(oClass, new AutoCompleteListStrategy(vClass));
  strategy.addStrategy('@@In', new AutoCompleteListStrategy(vClassIn));
  strategy.addStrategy('@@Not_In', new AutoCompleteListStrategy(vClassIn));
  //TODO don't hard-code integerbox
  strategy.addStrategy('@@Count_Is_Less_Than', new IntegerBoxStrategy('integerbox'));
  strategy.addStrategy('@@Count_Is_Equal_To', new IntegerBoxStrategy('integerbox'));
  strategy.addStrategy('@@Count_Is_Greater_Than', new IntegerBoxStrategy('integerbox'));
  this.registerCriteriaHandler(qName, new QualifiedCriteriaHandler(fName, qName, qClass, oClass, strategy));
};
QueryBuilderController.prototype.registerQualifiedForAutoCompleteListCriteriaHandler = function(fName, qName, qClass, oClass, vClass, vClassIn, sName, pName) {
    var strategy = new OperatorDelegateStrategy(oClass, new AutoCompleteListStrategy(vClass));
    var forAnyEntity = String.format('@@For_Any_Entity', sName);
    var forNoEntity = String.format('@@For_No_Entity', pName);
    var forAnyEntityIn = String.format('@@For_Any_Entity_In', sName);
    var forNoEntityIn = String.format('@@For_No_Entity_In', pName);
    strategy.addStrategy(forAnyEntity, new NoValueStrategy());
    strategy.addStrategy(forNoEntity, new NoValueStrategy());
    strategy.addStrategy(forAnyEntityIn, new AutoCompleteListStrategy(vClassIn));
    strategy.addStrategy(forNoEntityIn, new AutoCompleteListStrategy(vClassIn));
    this.registerCriteriaHandler(qName, new QualifiedCriteriaHandler(fName, qName, qClass, oClass, strategy));
};
QueryBuilderController.prototype.registerQualifiedDateBoxCriteriaHandler = function(fName, qName, qClass, oClass, vClassDefault, vClassWithin) {
  var strategy = new OperatorDelegateStrategy(oClass, new DateBoxStrategy(vClassDefault));
  strategy.addStrategy('@@Within', new DaysBoxStrategy(vClassWithin));
  strategy.addStrategy('@@Within_Last', new DaysBoxStrategy(vClassWithin));
  strategy.addStrategy('@@Within_Next', new DaysBoxStrategy(vClassWithin));
  this.registerCriteriaHandler(qName, new QualifiedCriteriaHandler(fName, qName, qClass, oClass, strategy));
};
QueryBuilderController.prototype.registerQualifiedDateTimeBoxCriteriaHandler = function(fName, qName, qClass, oClass, vClassDefault, vClassWithin) {
  var strategy = new OperatorDelegateStrategy(oClass, new DateTimeBoxStrategy(vClassDefault));
  strategy.addStrategy('@@Within', new DaysBoxStrategy(vClassWithin));
  strategy.addStrategy('@@Within_Last', new DaysBoxStrategy(vClassWithin));
  strategy.addStrategy('@@Within_Next', new DaysBoxStrategy(vClassWithin));
  this.registerCriteriaHandler(qName, new QualifiedCriteriaHandler(fName, qName, qClass, oClass, strategy));
};
QueryBuilderController.prototype.registerQualifiedDoubleBoxCriteriaHandler = function(fName, qName, qClass, oClass, vClass) {
  var strategy = new OperatorDelegateStrategy(oClass, new DoubleBoxStrategy(vClass));
  this.registerCriteriaHandler(qName, new QualifiedCriteriaHandler(fName, qName, qClass, oClass, strategy));
};
QueryBuilderController.prototype.registerQualifiedIntegerBoxCriteriaHandler = function(fName, qName, qClass, oClass, vClass) {
  var strategy = new OperatorDelegateStrategy(oClass, new IntegerBoxStrategy(vClass));
  this.registerCriteriaHandler(qName, new QualifiedCriteriaHandler(fName, qName, qClass, oClass, strategy));
};
QueryBuilderController.prototype.registerQualifiedMoneyBoxCriteriaHandler = function(fName, qName, qClass, oClass, vClass, cSymbol, cDecimalPlaces) {
  var strategy = new OperatorDelegateStrategy(oClass, new MoneyBoxStrategy(vClass, cSymbol, cDecimalPlaces));
  this.registerCriteriaHandler(qName, new QualifiedCriteriaHandler(fName, qName, qClass, oClass, strategy));
};
QueryBuilderController.prototype.registerQualifiedSingleSelectListCriteriaHandler = function(fName, qName, qClass, oClass, vClass) {
  var strategy = new OperatorDelegateStrategy(oClass, new SingleSelectListStrategy(vClass));
  this.registerCriteriaHandler(qName, new QualifiedCriteriaHandler(fName, qName, qClass, oClass, strategy));
};
QueryBuilderController.prototype.registerQualifiedTextBoxCriteriaHandler = function(fName, qName, qClass, oClass, vClass) {
  var strategy = new OperatorDelegateStrategy(oClass, new TextBoxStrategy(vClass));
  this.registerCriteriaHandler(qName, new QualifiedCriteriaHandler(fName, qName, qClass, oClass, strategy));
};
QueryBuilderController.prototype.registerQualifiedTimeBoxCriteriaHandler = function(fName, qName, qClass, oClass, vClass) {
  var strategy = new OperatorDelegateStrategy(oClass, new TimeBoxStrategy(vClass));
  this.registerCriteriaHandler(qName, new QualifiedCriteriaHandler(fName, qName, qClass, oClass, strategy));
};
QueryBuilderController.prototype.registerSimpleAutoCompleteListCriteriaHandler = function(fName, oClass, vClass, vClassIn) {
  var strategy = new OperatorDelegateStrategy(oClass, new AutoCompleteListStrategy(vClass));
  strategy.addStrategy('@@In', new AutoCompleteListStrategy(vClassIn));
  strategy.addStrategy('@@Not_In', new AutoCompleteListStrategy(vClassIn));
  //TODO don't hard-code integerbox
  strategy.addStrategy('count is less than', new IntegerBoxStrategy('integerbox'));
  strategy.addStrategy('count is equal to', new IntegerBoxStrategy('integerbox'));
  strategy.addStrategy('count is greater than', new IntegerBoxStrategy('integerbox'));
  this.registerCriteriaHandler(fName, new SimpleCriteriaHandler(fName, oClass, strategy));
};
QueryBuilderController.prototype.registerSimpleDateBoxCriteriaHandler = function(fName, oClass, vClassDefault, vClassWithin) {
  var strategy = new OperatorDelegateStrategy(oClass, new DateBoxStrategy(vClassDefault));
  strategy.addStrategy('@@Within', new DaysBoxStrategy(vClassWithin));
  strategy.addStrategy('@@Within_Last', new DaysBoxStrategy(vClassWithin));
  strategy.addStrategy('@@Within_Next', new DaysBoxStrategy(vClassWithin));
  this.registerCriteriaHandler(fName, new SimpleCriteriaHandler(fName, oClass, strategy));
};
QueryBuilderController.prototype.registerSimpleDateTimeBoxCriteriaHandler = function(fName, oClass, vClassDefault, vClassWithin) {
  var strategy = new OperatorDelegateStrategy(oClass, new DateTimeBoxStrategy(vClassDefault));  
  strategy.addStrategy('@@Within', new DaysBoxStrategy(vClassWithin));
  strategy.addStrategy('@@Within_Last', new DaysBoxStrategy(vClassWithin));
  strategy.addStrategy('@@Within_Next', new DaysBoxStrategy(vClassWithin));
  this.registerCriteriaHandler(fName, new SimpleCriteriaHandler(fName, oClass, strategy));
};
QueryBuilderController.prototype.registerSimpleDoubleBoxCriteriaHandler = function(fName, oClass, vClass) {
  var strategy = new OperatorDelegateStrategy(oClass, new DoubleBoxStrategy(vClass));
  this.registerCriteriaHandler(fName, new SimpleCriteriaHandler(fName, oClass, strategy));
};
QueryBuilderController.prototype.registerSimpleIntegerBoxCriteriaHandler = function(fName, oClass, vClass) {
  var strategy = new OperatorDelegateStrategy(oClass, new IntegerBoxStrategy(vClass));
  this.registerCriteriaHandler(fName, new SimpleCriteriaHandler(fName, oClass, strategy));
};
QueryBuilderController.prototype.registerSimpleMoneyBoxCriteriaHandler = function(fName, oClass, vClass, cSymbol, cDecimalPlaces) {
  var strategy = new OperatorDelegateStrategy(oClass, new MoneyBoxStrategy(vClass, cSymbol, cDecimalPlaces));
  this.registerCriteriaHandler(fName, new SimpleCriteriaHandler(fName, oClass, strategy));
};
QueryBuilderController.prototype.registerSimpleSingleSelectListCriteriaHandler = function(fName, oClass, vClass, pfName) {
  var strategy = new OperatorDelegateStrategy(oClass, new SingleSelectListStrategy(vClass));
  this.registerCriteriaHandler(fName, new SimpleCriteriaHandler(fName, oClass, strategy));
  if(pfName) {
      this.registerCriteriaHandler(pfName, new SimpleCriteriaHandler(fName, oClass, strategy));
  }
};
QueryBuilderController.prototype.registerSimpleTextBoxCriteriaHandler = function(fName, oClass, vClass) {
  var strategy = new OperatorDelegateStrategy(oClass, new TextBoxStrategy(vClass));
  this.registerCriteriaHandler(fName, new SimpleCriteriaHandler(fName, oClass, strategy));
};
QueryBuilderController.prototype.registerSimpleTimeBoxCriteriaHandler = function(fName, oClass, vClass) {
  var strategy = new OperatorDelegateStrategy(oClass, new TimeBoxStrategy(vClass));
  this.registerCriteriaHandler(fName, new SimpleCriteriaHandler(fName, oClass, strategy));
};
QueryBuilderController.prototype.registerSimpleSingleSelectDateBoxCriteriaHandler = function(fName, singleSelectFName, dateStartFName, dateEndFName, oClass, oClass2, vClass, vClass2, vClassIn2, sqValue, pfName) {
    var strategy = new OperatorDelegateStrategy(oClass, new SingleSelectListStrategy(vClass));
    var strategy2 = new OperatorDelegateStrategy(oClass2, new DateBoxStrategy(vClass2));
    strategy2.addStrategy('@@Within', new DaysBoxStrategy(vClassIn2));
    strategy2.addStrategy('@@Within_Last', new DaysBoxStrategy(vClassIn2));
    strategy2.addStrategy('@@Within_Next', new DaysBoxStrategy(vClassIn2));
    strategy2.removeStrategy('@@Does_Not_Exist');
    strategy2.removeStrategy('@@Not_Equal');
    var ssch = new SimpleCriteriaHandler(singleSelectFName, oClass, strategy);
    var dsch = new SimpleCriteriaHandler(dateStartFName, oClass2, strategy2);
    dsch.alternateFieldName = "endDate";
    ssch.singleOps = ['exists', 'does not exist'];//These values do not get localized because they are in actuallity values from the Option tag in the html
    ssch.parentFieldName = pfName;
    this.registerCriteriaHandler(fName, new SimpleMultipleCriteriaHandler(fName, [ssch, dsch], sqValue));
    if(pfName) {
        this.registerCriteriaHandler(pfName, new SimpleMultipleCriteriaHandler(fName, [ssch, dsch], sqValue));
    }
};
QueryBuilderController.prototype.getCriteria = function() {
  var selection = this.form.find('li.fields option:selected').first();
  return this.criteriaHandlers[selection.val()].getCriteria(this.form);
};
QueryBuilderController.prototype.reset = function() {
  var selection = this.form.find('li.fields option:selected').first();
  return this.criteriaHandlers[selection.val()].reset(this.form);
};
QueryBuilderController.prototype.setCriteria = function(criteria) {
  if(criteria instanceof Array) {
    var i;
    for(i=0;i<criteria.length;i++) {
      this.criteriaHandlers[criteria[i].fieldPropertyName].setCriteria(this.form, criteria);
    }
  } else {
    this.criteriaHandlers[criteria.fieldPropertyName].setCriteria(this.form, criteria);
  }
};
QueryBuilderController.prototype.hasCriteriaHandler = function(fpn) {
  return this.criteriaHandlers[fpn] != undefined && this.criteriaHandlers[fpn] != null;
};
QueryBuilderController.prototype.setDefaultCriteria = function() {
  var selection = this.form.find('li.fields option').first();
  var criteria = this.criteriaHandlers[selection.val()].getCriteria(this.form);
  this.criteriaHandlers[selection.val()].setCriteria(this.form, criteria);
};
QueryBuilderController.prototype.showCriteria = function() {
  this.form.find('li.criteria').hide();
  var selection = this.form.find('li.fields option:selected').first();
  this.criteriaHandlers[selection.val()].showCriteria(this.form);
};
QueryBuilderController.prototype.validate = function(errorMsg) {
  var selection = this.form.find('li.fields option:selected').first();
  this.criteriaHandlers[selection.val()].validate(this.form, errorMsg);
};

var controller = new QueryBuilderController();
