/**
 * Helper class to manage sequence numbers.
 */
function QueryBuilderSequence(selector) {
  this.prefix = selector.substring(1);
  this.index = 0;
};
QueryBuilderSequence.prototype.next = function() {
  return this.prefix + '_' + (++this.index);
};

/**
 * Helper class to manage error messages.
 */
function QueryBuilderErrors() {
  this.message = '';
  this.count = 0;
};
QueryBuilderErrors.prototype.add = function(msg) {
  this.message = this.message + msg + '\n';
  ++this.count;
};
QueryBuilderErrors.prototype.display = function() {
  if (this.count > 0) {
    alert(this.message);
  }
};

/**
 * The editor class.
 */
function QueryBuilderEditor(selector) {
  this.reference = $(selector);
  this.spanHtml = this.reference.find('.spanHtml');
  this.inputXml = this.reference.find('.inputXml');
  this.sequence = new QueryBuilderSequence(selector);
  this.launched = false;
}
QueryBuilderEditor.prototype.sequenceXml = function() {
  var t = this;
  t.sequence.index = 0;
  var xmlDoc = $.parseXML(this.inputXml.val());
  var $xmlDoc = $(xmlDoc);
  $xmlDoc.find('query-node').each(function() {
    $(this).attr('id', t.sequence.next());
  });
  var xml = (new XMLSerializer()).serializeToString(xmlDoc);
  this.inputXml.val(xml);
};
QueryBuilderEditor.prototype.desequenceXml = function() {
  // eliminate the id attributes
  var t = this;
  var xmlDoc = $.parseXML(t.inputXml.val());
  var $xmlDoc = $(xmlDoc);
  $xmlDoc.find('query-node').each(function() {
    $(this).removeAttr('id');
  });
  var xml = (new XMLSerializer()).serializeToString(xmlDoc);
  t.inputXml.val(xml);
};
QueryBuilderEditor.prototype.addCriteria = function(qid) { 
  var t = this;
  var e = $('#' + qid);
  var n = $(t.newCriteria());
  var id = n.attr('id');
  e.append(n);
  t.updateAndRender();
  
  // set up callback handlers
  var setup = function() {
    controller.setDefaultCriteria();
  };
  var update = function(criteria) {
    // save the entered criteria values in the 
    // newly created criteria element
    t.updateCriteria(id, criteria);
  };
  var cancel = function() {
    // remove the newly created criteria element
    $('#' + id).remove();
  };
  var error = function() {
	// no errors possible  
  };
  t.launch(id, setup, update, cancel, error);
};
QueryBuilderEditor.prototype.andCriteria = function(qid) {
  this.logicalCriteria(qid, 'and');
};
QueryBuilderEditor.prototype.editCriteria = function(qid) {
  var t = this;
  
  // setup callback handlers
  var setup = function() {
    var e = $('#' + qid);
    
    var f = e.find('.field');
    var v = e.find('.value');
    var fdn = f.attr('displayname');
    var fpn = f.attr('propertyname');
    var o = e.attr('operator');
    if (e.attr('operatordisplayvalue') == undefined) {
    	e.attr('operatordisplayvalue', o);
    }
    var odv = e.attr('operatordisplayvalue');
    var r = e.attr('restricted');
    var vdn = v.attr('displayname');
    var vsv = v.attr('searchvalue');
    var vsq = t.parseSubqueries(v.find('.query-node.crit'));
    
    if (fpn != undefined && fpn != '') {
      if (controller.hasCriteriaHandler(fpn)) {
        var c = new QueryCriteria(
            {
                fieldDisplayName: fdn,
                fieldPropertyName: fpn,
                operatorDisplayValue: odv,
                operator: o,
                valueDisplayName: vdn,
                valueSearchValue: vsv,
                restricted: r
            });
          for(var i=0;i<vsq.length;i++) {
              c.addValueSubquery(vsq[i]);
          }
        controller.setCriteria(c);  
      } else {
    	controller.setDefaultCriteria();
      }
    } else {
      controller.setDefaultCriteria();
    }
  };
  var update = function(criteria) {
    // save the entered criteria values in the
    // existing criteria element
    t.updateCriteria(qid, criteria);
  };
  var cancel = function() {
    // nothing to do here
  };
  var error = function() {
	var e = $('#' + qid);
	var f = e.find('.field');
    var fpn = f.attr('propertyname');
	if (fpn != undefined && fpn != '' && !controller.hasCriteriaHandler(fpn)) {
	  var errors = new QueryBuilderErrors();
	  errors.add('The specified criteria is no longer valid.  Please define a new criteria.');
	  errors.display();
	}
  };
  t.launch(qid, setup, update, cancel, error);
};
QueryBuilderEditor.prototype.logicalCriteria = function(qid, operator) {
  var t = this;
  var e = $('#' + qid);
  var p = e.parent();
  if (p.attr('operator') == operator) {
    // the parent operator is a match
    // just nest the new criteria in the parent
    t.addCriteria(p.attr('id'));
    return;
  }  
  var n = $(t.newCriteria());
  var id = n.attr('id');
  e.wrap('<div id="qid_' + t.sequence.next() + '" class="query-node" operator="' + operator + '" operatordisplayvalue="' + operator + '" primarysubquery="" insubquery="" restricted="false"/>');
  e.parent().append(n);
  t.updateAndRender();
  
  // setup callback handlers
  var setup = function() {
    controller.setDefaultCriteria();
  };
  var update = function(criteria) {
    // save the entered criteria values in the
    // newly created criteria element
    t.updateCriteria(id, criteria);
  };
  var cancel = function() {
    // remove the newly created criteria element
    $('#' + id).remove();
    $('#' + qid).unwrap();
  };
  var error = function() {
	  // no errors possible
  };
  t.launch(id, setup, update, cancel, error);
};
QueryBuilderEditor.prototype.updateCriteria = function(qid, criteria) {
  // write the specified criteria values to the given criteria element
  var e = $('#' + qid);
  var f = e.find('.field');
  var v = e.find('.value');
    e.children().remove(".query-node");
  if(criteria.subqueries.length > 0) {
      this.appendSubqueries(e, criteria.subqueries);
  }
  f.attr('displayname', criteria.fieldDisplayName);
  f.attr('propertyname', criteria.fieldPropertyName);
    f.attr('start-property-name', criteria.startFieldPropertyName);
    f.attr('end-property-name', criteria.endFieldPropertyName);
  e.attr('operator', criteria.operator);
  e.attr('operatordisplayvalue', criteria.operatorDisplayValue);
    e.attr('insubquery', criteria.inSubquery);
    e.attr('primarysubquery', criteria.primarySubquery);
  if (criteria.restricted == true || criteria.restricted == "true" || criteria.restricted == "checked") {
      e.attr('restricted', "true");
  } else {
      e.attr('restricted', "false");
  }
  v.attr('displayname', criteria.valueDisplayName);
  v.attr('searchvalue', criteria.valueSearchValue);
    v.children().remove('.query-node');
  if(criteria.valueSubqueries.length > 0) {
      this.appendSubqueries(v, criteria.valueSubqueries);
  }
};
QueryBuilderEditor.prototype.appendSubqueries = function(parent, subqueries) {
  var i, n, f, v;
  for(i=0; i<subqueries.length; i++) {
      n = $(this.newCriteria());
      n.attr('operator', subqueries[i].operator);
      n.attr('operatordisplayvalue', subqueries[i].operatorDisplayValue);
      n.attr('inSubquery', subqueries[i].inSubquery);
      n.attr('primarysubquery', subqueries[i].primarySubquery);
      f = n.find('.field');
      v = n.find('.value');
      f.attr('displayname', subqueries[i].fieldDisplayName);
      f.attr('propertyname', subqueries[i].fieldPropertyName);
      f.attr('start-property-name', subqueries[i].startFieldPropertyName);
      f.attr('end-property-name', subqueries[i].endFieldPropertyName);
      v.attr('displayname', subqueries[i].valueDisplayName);
      v.attr('searchvalue', subqueries[i].valueSearchValue);
      if(subqueries[i].subqueries.length > 0) {
          this.appendSubqueries(n, subqueries[i].subqueries);
      }
      if(subqueries[i].valueSubqueries.length > 0) {
          this.appendSubqueries(v, subqueries[i].valueSubqueries);
      }
      parent.append(n);
  }
};
QueryBuilderEditor.prototype.launch = function(qid, setup, update, cancel, error) {  
  // remove all highlights
  $('.action-menu.hover').removeClass('hover');
  $('.paren.selected').removeClass('selected');
  
  var t = this;
  t.launched = true;
  var e = $('#' + qid);
  e.addClass('editing');

  var overlay = $('.popover.pattern').last();

  //if the unopened overlay element can't be found
  //then it's already opened in another control
  if(overlay.length === 0) {
    //select the open overlay element
    var overlay = $('.popover.right').last();
    var overlayContainerId = overlay.parent().attr('id');
    var form = $('#' + overlayContainerId).find('form');

    //then close it, clicking on the Cancel button
    form.find('a.button.cancel').click();

    //after closing it, select the unopened overlay element
    overlay = $('.popover.pattern').last();
  }
  // append the overlay to the incoming query element
  var overlayContainerId = overlay.parent().attr('id');
  e.append(overlay);
  var form = e.find('form');
  controller.form = form;
    
  // execute anonymous setup function
  // will either initialize controller with
  // default criteria or a specified criteria
  setup();
  
  // tell the controller to hide/show its controllers
  controller.showCriteria();

  // re-render fields when new field is selected
  form.find('li.fields select').unbind('change').change(function() {
    controller.reset();
    controller.showCriteria();
  });

  // have the controller re-draw operators when custom field is selected/ changed
  form.find('li.customFields select').unbind('change').change(function() {
    controller.showCriteria();
  });
  // form.find('li.qualifiers select').unbind('change').change(function() {
  //   controller.showCriteria();
  // });
  form.find('li.operators select').unbind('change').change(function() {
    controller.showCriteria();
  });
  
  // cancel button 
  form.find('a.button.cancel').unbind('click').click(function(e) {
    e.preventDefault();
    t.launched = false;
    
    // hide the overlay and move it back to 
    // its original location
    overlay.addClass('pattern');
    $('#' + overlayContainerId).append(overlay);
    
    var e = $('#' + qid);
    e.removeClass('editing');
    
    // execute caller specific cancel behavior
    cancel();
    controller.reset();
    
    // update and rerender
    t.updateAndRender();
  });
  
  // update button
  form.find('a.button.update').unbind('click').click(function(e) {
    e.preventDefault();
    t.launched = false;
    
    // validate
    var errors = new QueryBuilderErrors();
    controller.validate(errors);
    if (errors.count > 0) {
      errors.display();
      return;
    }
    
    // hide the overlay and move it back 
    // to its original location
    overlay.addClass('pattern');
    $('#' + overlayContainerId).append(overlay);
    
    var e = $('#' + qid);
    e.removeClass('editing');
    
    // execute call specific update/save behavior
    update(controller.getCriteria());
    controller.reset();
    
    // update and rerender
    t.updateAndRender();
  });
  
  // make the overlay visible
  overlay.removeClass('pattern');
  e.addClass('editing');
  
  // display any errors
  error();
};
QueryBuilderEditor.prototype.newCriteria = function() {
  var t = this;
  return '<div id="qid_' + t.sequence.next() + '" class="query-node" operator="" restricted="" operatordisplayvalue="" insubquery="" primarysubquery=""> <div class="field" displayname="@@New_Criteria..." propertyname="" /> <div class="value" displayname="" searchvalue="" /></div>';
};
QueryBuilderEditor.prototype.orCriteria = function(qid) {
  this.logicalCriteria(qid, 'or');
};
QueryBuilderEditor.prototype.removeCriteria = function(qid) {
  var t = this;
  var e = t.spanHtml.find('#' + qid);
  var p = e.parent();
  e.remove();
  if (!p.hasClass('query-node')) {
    p.append($(t.newCriteria()));
  } else {
    var children = p.children('.query-node');
    if (children.length == 0) {
      // no remaining children
      // remove the parent
      t.removeCriteria(p.attr('id'));
      return;
    } else if (children.length == 1) {
      // only 1 child left, remove the wrapping query node defining and/or
      children.unwrap();
    } else {
      // more than 1 child left, leave the wrapping query node
    }
  }
  t.updateAndRender();
};
QueryBuilderEditor.prototype.swapCriteria = function(qid) { 
  var t = this;
  var e = t.spanHtml.find('#' + qid);
  var o = e.attr('operator');
  if (o == 'or') {
    o = 'and';
  } else if (o == 'and') {
    o = 'or';
  }
  e.attr('operator', o);
  e.attr('operatordisplayvalue', o);
  t.updateAndRender();
};
QueryBuilderEditor.prototype.updateAndRender = function() {
  var t = this;
  var before = t.inputXml.val();
  t.updatePre();
  t.render();
  t.updatePost();
  var after = t.inputXml.val();
  if (before != after) {
    t.updateNotify();
  }
};
QueryBuilderEditor.prototype.updatePre = function() {
  var xml = new QueryBuilderTransformer().transformEditHtmlToXml(this.spanHtml.html());
  this.inputXml.val(xml);
};
QueryBuilderEditor.prototype.updatePost = function() { 
  this.desequenceXml();
  
  // elminate the hidden xml if it is not a complete and functioning query
  var t = this;
  var xmlDoc = $.parseXML(t.inputXml.val());
  var $xmlDoc = $(xmlDoc);
  $xmlDoc.find('query-node').each(function() {
    var o = $(this).attr('operator');
    if (o == undefined || $.trim(o) == '') {
      t.inputXml.val('');
    }
  });
};
QueryBuilderEditor.prototype.updateNotify = function() {
  this.inputXml.change();
};
QueryBuilderEditor.prototype.render = function() { 
  var t = this;
  
  // grab latest xml and populate the display html
  var transformer = new QueryBuilderTransformer();
  var xml = t.inputXml.val();
  if (xml == undefined || xml == '') {
    xml = t.newCriteria();
    t.inputXml.val(xml);
  }
  var html = transformer.transformXmlToEditHtml(xml);
  t.spanHtml.html(html);
      
  // add all of the menu hover/click actions
  t.spanHtml.find('.paren:not(.nomenu)').mouseleave(function() {
    if (!t.launched) {
      $(this).removeClass('selected');
      $(this).find('.action-menu.hover').removeClass('hover');
    }
  });
  t.spanHtml.find('.crit:not(.nomenu)').hover(function() {
    if (!t.launched) {
      $('.action-menu.hover').removeClass('hover');
      $('.paren.selected').removeClass('selected');
      $(this).addClass('hover');
    }
  }, function() {
    if (!t.launched) {
      $(this).removeClass('hover');
      $(this).find('.action-menu').removeClass('hover');
    }
  }).click(function(e) {

//    PAJ 4/1/13 - was causing the checkboxes nested in the editor window to be uncheckable.
//    I haven't found any problems caused by removing this yet.
//    e.preventDefault();
    if (!t.launched) {
      $(this).find('.action-menu').addClass('hover');
    }
  });  
  t.spanHtml.find('.paren-hitbox').mouseover(function() {
    if (!t.launched) {
      $('.action-menu.hover').removeClass('hover');
      $('.paren.selected').removeClass('selected');
      $(this).parent().addClass('selected');
    }
  }).click(function(e) {
    e.preventDefault();
    if (!t.launched) {
      $(this).siblings(".action-menu").addClass("hover");
    }
  });
  // increase size of the hit box
  t.spanHtml.findWithSelf('.paren:not(.nomenu)').each(function() {
    var parentHeight = $(this).outerHeight();
    $(this).find('.paren-hitbox').css('height', parentHeight + 'px');
  });
  
  // add all of the menu item click actions
  t.spanHtml.find('a.query-node-remove').click(function(e) {
    e.preventDefault();
    if (!t.launched) {
      var qid = $(this).closest('.query-node').attr('id');
      t.removeCriteria(qid);
    }
  });
  t.spanHtml.find('a.query-node-add').click(function(e) {
    e.preventDefault();
    if (!t.launched) {
      var qid = $(this).closest('.query-node').attr('id');
      t.addCriteria(qid);
    }
  });
  t.spanHtml.find('a.query-node-and').click(function(e) {
    e.preventDefault();
    if (!t.launched) {
      var qid = $(this).closest('.query-node').attr('id');
      t.andCriteria(qid);
    }
  });
  t.spanHtml.find('a.query-node-or').click(function(e) {
    e.preventDefault();
    if (!t.launched) {
      var qid = $(this).closest('.query-node').attr('id');
      t.orCriteria(qid);
    }
  });
  t.spanHtml.find('a.query-node-swap').click(function(e) {
    e.preventDefault();
    if (!t.launched) {
      var qid = $(this).closest('.query-node').attr('id');
      t.swapCriteria(qid);
    }
  });
  t.spanHtml.find('a.query-node-edit').click(function(e) {
    e.preventDefault();
    if (!t.launched) {
      var qid = $(this).closest('.query-node').attr('id');
      t.editCriteria(qid);
    }
  });
};
QueryBuilderEditor.prototype.init = function() {
  var t = this;
  var xml = t.inputXml.val();
  if (xml == undefined || $.trim(xml) == '') {
    xml = new QueryBuilderTransformer().transformEditHtmlToXml(t.newCriteria());
    t.inputXml.val(xml);
  } else {
    t.sequenceXml();
  }
  t.render();
  t.updatePost();
};
QueryBuilderEditor.prototype.parseSubqueries = function(subqueries) {
    var array = [];
    for(var i=0; i<subqueries.length; i++) {
        var subquery = $(subqueries[i]);
        var f = subquery.find('.field');
        var v = subquery.find('.value');
        var fdn = f.attr('displayname');
        var fpn = f.attr('propertyname');
        var o = subquery.attr('operator');
        if (subquery.attr('operatordisplayvalue') == undefined) {
            subquery.attr('operatordisplayvalue', o);
        }
        var odv = subquery.attr('operatordisplayvalue');
        var r = subquery.attr('restricted');
        var vdn = v.attr('displayname');
        var vsv = v.attr('searchvalue');
        var vsq = this.parseSubqueries(v.find('.query-node.crit'));
        array.push(new QueryCriteria(
            {
                fieldDisplayName: fdn,
                fieldPropertyName: fpn,
                operatorDisplayValue: odv,
                operator: o,
                valueDisplayName: vdn,
                valueSearchValue: vsv,
                restricted: r,
                valueSubqueries: vsq
            }));
    }
    return array;
};



