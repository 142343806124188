function QueryBuilderTransformer() {
}
/**
 * Transform incoming xml to viewable html.
 * @param xml the incoming xml
 * @return the viewable html
 */
QueryBuilderTransformer.prototype.transformXmlToViewHtml = function (xml) {
    var html = xml
        .replace(/<query-node/gi, '<div class="query-node"')
        .replace(/query-node>/gi, 'div>')
        .replace(/<field/gi, '<div class="field"')
        .replace(/field>/gi, 'div>')
        .replace(/<value/gi, '<div class="value"')
        .replace(/value>/gi, 'div>')
        .replace(/display-name/gi, 'displayname')
        .replace(/[^-]property-name/gi, 'propertyname')
        .replace(/search-value/gi, 'searchvalue')
        .replace(/&apos;/gi, '&#39;');              // see BARO-8837

    var $html = $(html);
    $html.wrap('<span/>');
    $html = $html.parent();

    // Add display-name's as text() (on all DIV's)
    $html.findWithSelf('div').each(function () {
        var t = $(this);
        if (t.attr('displayname') != undefined) {
            t.append(t.attr('displayname'));
        }
    });

    // Add more specific classes for display
    $html.find('.query-node').each(function () {
        var t = $(this);
        // make id attribute unique from xml id
        var id = t.attr('id');
        if (id != undefined) {
            t.attr('id', 'qid_' + id);
        }
        // add class to indicate restricted criteria
        var restricted = t.attr("restricted");
        if (restricted != null && restricted == "true" || restricted == "checked") {
            t.addClass('restricted');
        }

        // subqueries - criteria _within_ other criteria values - only used in lifecycle roadmap
        if (t.attr('insubquery') === 'true') {

            if (t.attr('primarysubquery') === 'true') {
                t.addClass('paren nomenu subquery');
            } else {
                t.addClass('crit nomenu');
            }
            // ADD AN INLINE OPERATOR ELEMENT FOR LEGIBILITY
            if (t.attr('operatordisplayvalue') == undefined) {
                t.attr('operatordisplayvalue', t.attr('operator'));
            }
            t.find('.field').after(' ').after('<div class="inline-operator">' + t.attr('operatordisplayvalue') + '</div>').after(' ');

        } else if ((t.children('.query-node').length || t.children('.value').children('.query-node').length) && t.attr('operator') !== 'in subquery' && t.attr('operator') !== 'not in subquery') {
            // ADD PAREN CLASS IF THERE ARE CHILD NODES
            t.addClass('paren');

            // ADD AN OPERATOR BETWEEN ALL OF ITS CHILD NODES
            if (t.attr('operatordisplayvalue') == undefined) {
                t.attr('operatordisplayvalue', t.attr('operator'));
            }
            t.children('.query-node:not(:last)').after(' ').after('<div class="operator">' + t.attr('operatordisplayvalue') + '</div>').after(' ');
        } else {
            // ADD CRIT CLASS TO ALL LEAF NODES
            t.addClass('crit');

            // ADD AN INLINE OPERATOR ELEMENT FOR LEGIBILITY
            if (t.attr('operatordisplayvalue') == undefined) {
                t.attr('operatordisplayvalue', t.attr('operator'));
            }
            t.find('.field').after(' ').after('<div class="inline-operator">' + t.attr('operatordisplayvalue') + '</div>').after(' ');
        }
    });

    $html.findWithSelf('div').each(function () {
        var t = $(this);
        var attrs = t.get(0).attributes;
        if (attrs != null) {
            for (attr in attrs) {
                if (attr != null) {
                    var attrName = attr.nodeName;
                    // skip class, id attribute
                    if (attrName != 'class' && attrName != 'id') {
                        t.removeAttr(attrName);
                    }
                }
            }
        }
    });

    $html.find('.paren:not(.nomenu)').prepend('<span class="paren-wrapper">(</span>').append('<span class="paren-wrapper">)</span>');

    // ADD "NO-RENDER" TO ROOT PAREN
    $html.addClass('no-render');
    return $html.html();
};

QueryBuilderTransformer.prototype.transformXmlToEditHtml = function (xml) {
    var $html = $(this.transformXmlToViewHtml(xml));
    $html.wrap('<span/>');
    $html = $html.parent();

    // add action menu for add/remove/and/or
    // menu item click actions are applied elsewhere
    $html.findWithSelf("div.paren:not(.nomenu)").filter("div.paren").each(function () {
        $(this).prepend($('<ul class="action-menu dropdown-menu"><li><a class="query-node-add" href="#">+ @@Criteria</a></li><li><a class="query-node-swap" href="#">Swap @@Or/@@And</a></li><li><a class="query-node-remove" href="#">@@Remove</a></li></ul>'));
        $(this).prepend($("<div class='paren-hitbox' />"));
    });

    // add action menu for edit/remove/add and condition/add or condition
    // menu item click actions are applied elsewhere
    $html.findWithSelf("div.crit:not(.nomenu)").filter("div.crit").each(function () {
        $(this).prepend($('<ul class="action-menu dropdown-menu"><li><a class="query-node-edit" href="#">@@Edit...</a></li><li><a class="query-node-or" href="#">+ @@Or</a></li><li><a class="query-node-and" href="#">+ @@And</a></li><li><a class="query-node-remove" href="#">@@Remove</a></li></ul>'));
    });

    return $html.html();
};
QueryBuilderTransformer.prototype.transformEditHtmlToXml = function (html) {
    var $html = $(html);
    // remove wrapping div if present
    if ($html.attr('class') === undefined || $html.attr('class') == '') {
        $html = $($html.html());
    }
    var xml = $.trim(this.transformEditChildrenToXml($html));
    return xml;
};
QueryBuilderTransformer.prototype.transformEditChildrenToXml = function ($children) {
    var t = this;
    var xml = '';
    $children.each(function () {
        var c = $(this);
        if (c.hasClass('query-node')) {
            var o = c.attr('operator');
            if (c.attr('operatordisplayvalue') == undefined) {
                c.attr('operatordisplayvalue', o);
            }
            var odv = c.attr('operatordisplayvalue');
            var i = c.attr('id').substring(4);
            var r = c.attr('restricted');
            var sq = c.attr('insubquery');
            var psq = c.attr('primarysubquery');
            if (r == "true" || r == "checked") {
                xml += '<query-node id="' + i + '" operator="' + o + '" operatordisplayvalue="' + odv + '" insubquery="' + sq + '" primarysubquery="' + psq + '" restricted="true">';
            } else {
                xml += '<query-node id="' + i + '" operator="' + o + '" operatordisplayvalue="' + odv + '" insubquery="' + sq + '" primarysubquery="' + psq + '" restricted="false">';
            }
            xml += t.transformEditChildrenToXml(c.children());
            xml += '</query-node> ';
        } else if (c.hasClass('field')) {
            var d = c.attr('displayname');
            var p = c.attr('propertyname');
            var pp = c.attr('parentpropertyname');
            var s = c.attr('start-property-name');
            var e = c.attr('end-property-name');
            xml += '<field ';
            if (d != undefined) {
                xml += 'display-name="' + t.xmlEscape(d) + '" ';
            }
            if (p != undefined) {
                xml += 'property-name="' + t.xmlEscape(p) + '" ';
            }
            if (pp != undefined) {
                xml += 'parent-property-name"' + t.xmlEscape(pp) + '" ';
            }
            if (s != undefined) {
                xml += 'start-property-name="' + t.xmlEscape(s) + '" ';
            }
            if (e != undefined) {
                xml += 'end-property-name="' + t.xmlEscape(e) + '" ';
            }
            xml += '/> ';
        } else if (c.hasClass('value')) {
            var d = c.attr('displayname');
            var s = c.attr('searchvalue');
            xml += '<value ';
            if (d != undefined) {
                xml += 'display-name="' + t.xmlEscape(d) + '" ';
            }
            if (s != undefined) {
                xml += 'search-value="' + t.xmlEscape(s) + '" ';
            }
            xml += '> ';
            xml += t.transformEditChildrenToXml(c.children());
            xml += '</value>';
        }
    });
    return xml;
};
QueryBuilderTransformer.prototype.xmlEscape = function (s) {
    return s.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;').replace(/'/g, '&apos;');
};
