function QueryBuilderViewer(selector) {
  this.reference = $(selector);
  this.spanHtml = this.reference.find('.spanHtml');
  this.inputXml = this.reference.find('.inputXml');
}
QueryBuilderViewer.prototype.init = function() {
  var transformer = new QueryBuilderTransformer();

  if (this.inputXml.length > 0){
    var html = transformer.transformXmlToViewHtml(this.inputXml.val());
    this.spanHtml.html(html);
  }

};

