/**
 * A format function for strings.
 */
String.prototype.format = String.prototype.f = function() {
  var s = this, i = arguments.length;

  while (i--) {
    s = s.replace(new RegExp('\\{' + i + '\\}', 'gm'), arguments[i]);
  }
  return s;
};

/**
 * Formats the number according to the 'format’ string; adherses to the american
 * number standard where a comma is inserted after every 3 digits. note: there
 * should be only 1 contiguous number in the format, where a number consists of
 * digits, period, and commas any other characters can be wrapped around this
 * number, including '$’, '%’, or text examples (123456.789): '0′ - (123456)
 * show only digits, no precision '0.00′ - (123456.78) show only digits, 2
 * precision '0.0000′ - (123456.7890) show only digits, 4 precision '0,000′ -
 * (123,456) show comma and digits, no precision '0,000.00′ - (123,456.78) show
 * comma and digits, 2 precision '0,0.00′ - (123,456.78) shortcut method, show
 * comma and digits, 2 precision
 * 
 * @method format
 * @param format
 *            {string} the way you would like to format this text
 * @return {string} the formatted number
 * @public
 */
Number.prototype.format = function(format) {
  var hasComma = -1 < format.indexOf(','), psplit = format.split('.'), that = this;
  // compute precision
  if (1 < psplit.length) {
    // fix number precision
    that = that.toFixed(psplit[1].length);
  }
  // error: too many periods
  else if (2 < psplit.length) {
    throw ('NumberFormatException: invalid format, formats should have no more than 1 period: ' + format);
  }
  // remove precision
  else {
    that = that.toFixed(0);
  }
  // get the string now that precision is correct
  var fnum = that.toString();
  // format has comma, then compute commas
  if (hasComma) {
    // remove precision for computation
    psplit = fnum.split('.');
    var cnum = psplit[0], parr = [], j = cnum.length, m = Math.floor(j / 3), n = cnum.length % 3 || 3; // n
    // break the number into chunks of 3 digits; first chunk may be less
    // than 3
    for ( var i = 0; i < j; i += n) {
      if (i != 0) {
        n = 3;
      }
      parr[parr.length] = cnum.substr(i, n);
      m -= 1;
    }
    // put chunks back together, separated by comma
    fnum = parr.join(',');
    // add the precision back in
    if (psplit[1]) {
      fnum += '.' + psplit[1];
    }
  }
  // replace the number portion of the format with fnum
  return format.replace(/[\d,?\.?]+/, fnum);
};

function formatDateTimeString(string) {
  var components = string.split(" "),
    dateTimeObj = {
      date: components[0],
      time: components[1]
    };
  if (components.length == 3) { dateTimeObj.meridiem = components[2]; }

  if (window.localizedDateFormat === 'DD/MM/YYYY') {
    return formatDateTimeString_DD_MM_YYYY(dateTimeObj.date, dateTimeObj.time);
  }
  return formatDateTimeString_MM_DD_YYYY(dateTimeObj.date, dateTimeObj.time, dateTimeObj.meridiem);
}
function formatTwelveToTwentyFour(timeString, meridiem) {
  if (meridiem == 'AM') {
    return timeString
  }
  if (meridiem == 'PM') {
    var components = timeString.split(":"),
      hourInt = parseInt(components[0]);
    return ((hourInt + 12).toString() + ':' + components[1]).trim();
  }
}
function formatDateTimeString_DD_MM_YYYY(dateString, timeString) {
  var dateComponents = dateString.split('/'),
    timeComponents = 'T' + timeString + ':00' ;
  return dateComponents[2] + '-' + dateComponents[1] + '-' + dateComponents[0] + timeComponents;
}
function formatDateTimeString_MM_DD_YYYY(dateString, timeString , meridiem) {
  var timeString_24Hour = formatTwelveToTwentyFour(timeString, meridiem);
  var dateComponents = dateString.split('/'),
    timeComponents = 'T' + timeString_24Hour + ':00' ;
  return dateComponents[2] + '-' + dateComponents[0] + '-' + dateComponents[1] + timeComponents;
}

function formatDisplayDate(date) {
  if (window.localizedDateFormat === 'DD/MM/YYYY') {
    return formatDisplayDate_DD_MM_YYYY(date);
  }
  return formatDisplayDate_MM_DD_YYYY(date)

}
function formatDisplayDate_DD_MM_YYYY(date) {
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var day = date.getDate();
  var formatted = '';

  if (day < 10) {
    formatted += '0';
  }
  formatted += day + '/';
  if (month < 10) {
    formatted += '0';
  }
  formatted += month + '/';
  formatted += year;
  return formatted;
}
function formatDisplayDate_MM_DD_YYYY(date) {
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var day = date.getDate();
  var formatted = '';
  if (month < 10) {
    formatted += '0';
  }
  formatted += month + '/';
  if (day < 10) {
    formatted += '0';
  }
  formatted += day + '/';
  formatted += year;
  return formatted;
}
function formatDisplayDateTime(date) {
  return formatDisplayDate(date) + ' ' + formatDisplayTime(date);
}
function formatDisplayTime(date) {
  if (window.localizedDateFormat === 'DD/MM/YYYY') {
    return formatDisplayTime_DD_MM_YYYY(date);
  }
  return formatDisplayTime_MM_DD_YYYY(date);
}
function formatDisplayTime_DD_MM_YYYY(date) {
  var hour = date.getHours();
  var minute = date.getMinutes();
  return hour+':'+minute;
}
function formatDisplayTime_MM_DD_YYYY(date) {
  var hour = date.getHours();
  var minute = date.getMinutes();

  var amPm = 'AM';
  if (hour >= 12) {
    amPm = 'PM';
    if (hour > 12) {
      hour = hour - 12;
    }
  } else if (hour == 0) {
    hour = 12;
  }

  var formatted = '';
  if (hour < 10) {
    formatted += '0';
  }
  formatted += hour + ':';
  if (minute < 10) {
    formatted += '0';
  }
  formatted += minute;
  formatted += ' ' + amPm;
  return formatted;
}
function formatSearchDate(date) {
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var day = date.getDate();

  var formatted = '' + year;
  if (month < 10) {
    formatted += '0';
  }
  formatted += month;
  if (day < 10) {
    formatted += '0';
  }
  formatted += day;
  return formatted;
}
function formatSearchDateTime(date) {
  return formatSearchDate(date) + formatSearchTime(date);
}
function formatSearchTime(date) {
  var hour = date.getHours();
  var minute = date.getMinutes();

  var formatted = '';
  if (hour < 10) {
    formatted += '0';
  }
  formatted += hour;
  if (minute < 10) {
    formatted += '0';
  }
  formatted += minute;
  return formatted;
}
function formatDisplayDouble(dValue) {
  var negative = dValue < 0;
  if (negative) {
    dValue = dValue * -1;
  }
  var formatted = dValue.format('0,0.000');
  if (negative) {
    formatted = '- ' + formatted;
  }
  return formatted;
}
function formatDisplayInteger(iValue) {
  var negative = iValue < 0;
  if (negative) {
    iValue = iValue * -1;
  }
  var formatted = iValue.format('0,0');
  if (negative) {
    formatted = '- ' + formatted;
  }
  return formatted;
}
function formatDisplayMoney(currencySymbol, decimalPlaces, mValue) {
  var p = '0,0';
  if (decimalPlaces > 0) {
    p = p + '.';
    for (var i=0;i<decimalPlaces;i++) {
      p = p + '0';
    }
  }
  var negative = mValue < 0;
  if (negative) {
    mValue = mValue * -1;
  }
  var formatted = currencySymbol + mValue.format(p);
  if (negative) {
    formatted = '- ' + formatted;
  }
  return formatted;
}
function formatSearchDouble(dValue) {
  return dValue.format('0.000');
}
function formatSearchInteger(iValue) {
  return iValue.format('0');
}
function formatSearchMoney(currencySymbol, decimalPlaces, mValue) {
  var p = '0';
  if (decimalPlaces > 0) {
    p = p + '.';
    for (var i=0;i<decimalPlaces;i++) {
      p = p + '0';
    }
  }
  return mValue.format(p);
}