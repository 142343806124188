var DATE_PATTERN = /^((0?[13578]|10|12)(-|\/)(([1-9])|(0[1-9])|([12])([0-9]?)|(3[01]?))(-|\/)((19)([2-9])(\d{1})|([2-9])([0-9])([0-9])(\d{1})|([8901])(\d{1}))|(0?[2469]|11)(-|\/)(([1-9])|(0[1-9])|([12])([0-9]?)|(3[0]?))(-|\/)((19)([2-9])(\d{1})|([2-9])([0-9])([0-9])(\d{1})|([8901])(\d{1})))$/;
var DATE_PATTERN_DD_MM_YYY = /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[1,3-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;
var TIME_PATTERN = /^(([0]?[1-9]|1[0-2])(:|\.)[0-5][0-9]?( )?(AM|am|aM|Am|PM|pm|pM|Pm))$/;
var DATE_TIME_PATTERN =/^((0?[13578]|10|12)(-|\/)(([1-9])|(0[1-9])|([12])([0-9]?)|(3[01]?))(-|\/)((19)([2-9])(\d{1})|([2-9])([0-9])([0-9])(\d{1})|([8901])(\d{1}))|(0?[2469]|11)(-|\/)(([1-9])|(0[1-9])|([12])([0-9]?)|(3[0]?))(-|\/)((19)([2-9])(\d{1})|([2-9])([0-9])([0-9])(\d{1})|([8901])(\d{1})))?( )?(([0]?[1-9]|1[0-2])(:|\.)[0-5][0-9]?( )?(AM|am|aM|Am|PM|pm|pM|Pm))$/;
var DOUBLE_PATTERN = /^[-+]?[0-9]\d{0,11}(\.\d{1,3})?$/;
var INTEGER_PATTERN = /^[-+]?[0-9]\d{0,11}$/;
var MONEY_PATTERN0 = /^[0-9]\d{0,11}$/;
var MONEY_PATTERN1 = /^[-+]?[0-9]\d{0,11}(\.\d{1,1})?$/;
var MONEY_PATTERN2 = /^[-+]?[0-9]\d{0,11}(\.\d{1,2})?$/;
var MONEY_PATTERN3 = /^[-+]?[0-9]\d{0,11}(\.\d{1,3})?$/;
var MONEY_PATTERN4 = /^[-+]?[0-9]\d{0,11}(\.\d{1,4})?$/;

function isValidDate(value) {
  if(window.localizedDateFormat === 'DD/MM/YYYY') {
    return isValidPattern(value, DATE_PATTERN_DD_MM_YYY);
  }
  return isValidPattern(value, DATE_PATTERN);
}
function isValidDateTime(value) {
  return isValidPattern(value, DATE_TIME_PATTERN);
}
function isValidDays(value) {
  var s = $.trim(value).replace(/,*/g, '').replace(/\s*/g, '');
  var valid = isValidPattern(s, INTEGER_PATTERN);
  if (valid) {
    var i = new Number(s);
    valid = (i >= 1) && (i <= 9999);
  }
  return valid;
}
function isValidDouble(value) {
  var s = $.trim(value).replace(/,*/g, '').replace(/\s*/g, '');
  var valid = isValidPattern(s, DOUBLE_PATTERN);
  if (valid) {
    var d = new Number(s);
    valid = (d >= -1000000000000) && (d <= 1000000000000);
  }
  return valid;
}
function isValidInteger(value) {
  var s = $.trim(value).replace(/,*/g, '').replace(/\s*/g, '');
  var valid = isValidPattern(s, INTEGER_PATTERN);
  if (valid) {
    var i = new Number(s);
    valid = (i >= -2147483648) && (i <= 2147483647);
  }
  return valid;
}
function isValidMoney(currencySymbol, decimalPlaces, value) {
  var p = MONEY_PATTERN0;
  if (decimalPlaces == 1) {
    p = MONEY_PATTERN1;
  } else if (decimalPlaces == 2) {
    p = MONEY_PATTERN2;
  } else if (decimalPlaces == 3) {
    p = MONEY_PATTERN3;
  } else if (decimalPlaces == 4) {
    p = MONEY_PATTERN4;
  }
  var s = $.trim(value).replace(/,*/g, '').replace(/\s*/g, '').replace(currencySymbol, '');
  var valid = isValidPattern(s, p);
  if (valid) {
    var d = new Number(s);
    valid = (d >= -1000000000000) && (d <= 1000000000000);
  }
  return valid;
}
function isValidTime(value) {
  return isValidPattern(value, TIME_PATTERN);
}
function isValidPattern(value, pattern) {
  if (value.match(pattern)) {
    return true;
  }
  return false;
}