/**
 * Strategy used to find/get/set/validate auto completes.
 * 
 * @param selector -
 *          the jquery selector used to find the div containing the auto
 *          complete field.
 */
function AutoCompleteListStrategy(selector) {
  this.selector = 'li.values.' + selector;
}
AutoCompleteListStrategy.prototype.field = function(form) {
  return form.find(this.selector + ' input').first();
};
AutoCompleteListStrategy.prototype.restrictedCheckBox = function(form) {
  return form.find(this.selector + ' .restricted-checkbox').first();
};
AutoCompleteListStrategy.prototype.get = function(form) {
  var field = this.field(form);
  var c = new Array();
  c['text'] = field.val();
  c['val'] = field.attr('title');
  var restrictedCheckBox = this.restrictedCheckBox(form);
  c['restricted'] = restrictedCheckBox.prop('checked');
  return c;
};
AutoCompleteListStrategy.prototype.reset = function(form) {
  var defaultQueryValue = form.find(this.selector + ' label').filterByAttribute('for', 'defaultQueryValue').text();
  var field = this.field(form);
  field.val(defaultQueryValue);
  field.attr('title', defaultQueryValue);
  var restrictedCheckBox = this.restrictedCheckBox(form);
  restrictedCheckBox.prop('checked', 'false');
};
AutoCompleteListStrategy.prototype.set = function(form, criteria) {
  var field = this.field(form);
  field.val(criteria['text']);
  field.attr('title', criteria['val']);
  var restrictedCheckBox = this.restrictedCheckBox(form);
  if (criteria['restricted'] != null && criteria['restricted']  == "true" || criteria['restricted'] == "checked" || criteria['restricted'] === true) {
    restrictedCheckBox.prop('checked','checked');
  } else {
    restrictedCheckBox.removeAttr('checked');
  }
};
AutoCompleteListStrategy.prototype.show = function(form) {
  form.find(this.selector).show();
};
AutoCompleteListStrategy.prototype.valid = function(form) {
  var field = this.field(form);
  var searchValue = field.attr('title');
  var defaultQueryValue = form.find(this.selector + ' label').filterByAttribute('for', 'defaultQueryValue').text();
  return $.trim(searchValue) != '' && searchValue != defaultQueryValue && searchValue != field.val();
};
AutoCompleteListStrategy.prototype.validate = function(form, errors) {
  if (!this.valid(form)) {
    errors.add(form.find(this.selector + ' label').filterByAttribute('for', 'requiredErrorMessage').text());
  }
};

/**
 * Strategy used to find/get/set/validate single select lists.
 * 
 * @param selector -
 *          the jquery selector used to find the div containing the select list.
 */
function SingleSelectListStrategy(selector) {
  this.selector = 'li.values.' + selector;
}
SingleSelectListStrategy.prototype.field = function(form) {
  return form.find(this.selector + ' option:selected').first();
};
SingleSelectListStrategy.prototype.get = function(form) {
  var field = this.field(form);
  var c = new Array();
  c['text'] = field.text();
  c['val'] = field.val();
  c['isParent'] = $(field).hasClass('is-parent');
  return c;
};
SingleSelectListStrategy.prototype.reset = function(form) {
  var field = form.find(this.selector + ' option').first();
  field.attr('selected', 'selected');
};
SingleSelectListStrategy.prototype.set = function(form, criteria) {
  var field = form.find(this.selector + ' select').first()
  if(field.hasClass('adhoc-select-2')) {
      field.select2({
          width: '100%',
          templateResult: function(data) {
            if(!data.element){
              return data.text;
            }

            var option = $(data.element);

            var wrapper = $('<span></span>');
            wrapper.addClass(option[0].className);
            wrapper.text(data.text);

            return wrapper;
          },
          dropdownCssClass: 'parent-label-group'
      }).val(criteria['val']);
  } else {
      field.find('option').filterByValue(criteria['val']).first().attr('selected', 'selected');
  }
};
SingleSelectListStrategy.prototype.show = function(form) {
  form.find(this.selector).show();
};
SingleSelectListStrategy.prototype.valid = function(form) {
  var field = this.field(form);
  return $.trim(field.val()) != '';
};
SingleSelectListStrategy.prototype.validate = function(form, errors) {
  if (!this.valid(form)) {
    errors.add(form.find(this.selector + ' label').filterByAttribute('for', 'requiredErrorMessage').text());
  }
};

function buildDate(dateString) {
  if (window.localizedDateFormat === 'DD/MM/YYYY') {
    var dateParts = dateString.split("/");
    return new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);
  } else {
    return new Date(dateString);
  }
}

/**
 * Strategy used to find/get/set/validate date boxes.
 * 
 * @param selector -
 *          the jquery selector used to find the div containing the input field.
 */
function DateBoxStrategy(selector) {
  this.selector = 'li.values.' + selector;
}
DateBoxStrategy.prototype.field = function(form) {
  return form.find(this.selector + ' input').first();
};
DateBoxStrategy.prototype.get = function(form) {
  var field = this.field(form);
  var date = buildDate(field.val());
  var c = new Array();
  c['text'] = formatDisplayDate(date);
  c['val'] = formatSearchDate(date);
  return c;
};
DateBoxStrategy.prototype.reset = function(form) {
  var field = this.field(form);
  field.val('');
};
DateBoxStrategy.prototype.set = function(form, criteria) {
  var field = this.field(form);
  field.val(criteria['text']);
};
DateBoxStrategy.prototype.show = function(form) {
  // first, we need to reset the calendar popup widget (for some reason)
  form.find(this.selector + ' img').remove();
  var fresh = $('<input type="text" class="date" />');
  var stale = form.find(this.selector + ' input').first();
  var options = datePickerOptions;
  if(options){
      options.autoclose = true;
  }
  fresh.val(stale.val());
  fresh.attr('title', stale.attr('title'));
  stale.before(fresh);
  stale.remove();
  fresh.datepicker(options);
  form.find(this.selector).show();
};
DateBoxStrategy.prototype.validRequired = function(form) {
  var field = this.field(form);
  var val = $.trim(field.val());
  return val != undefined && val != '';
};
DateBoxStrategy.prototype.validFormat = function(form) {
  var field = this.field(form);
  var val = $.trim(field.val());
  return isValidDate(val);
};
DateBoxStrategy.prototype.valid = function(form) {
  return this.validRequired(form) && this.validFormat(form);
};
DateBoxStrategy.prototype.validate = function(form, errors) {
  if (!this.validRequired(form)) {
    errors.add(form.find(this.selector + ' label').filterByAttribute('for', 'requiredErrorMessage').text());
  } else if (!this.validFormat(form)) {
    errors.add(form.find(this.selector + ' label').filterByAttribute('for', 'formatErrorMessage').text());
  }
};

/**
 * Strategy used to find/get/set/validate datetime boxes.
 * 
 * @param selector -
 *          the jquery selector used to find the div containing the input field.
 */
function DateTimeBoxStrategy(selector) {
  this.selector = 'li.values.' + selector;
  this.compiled = false;
}
DateTimeBoxStrategy.prototype.field = function(form) {
  return form.find(this.selector + ' input').first();
};
DateTimeBoxStrategy.prototype.get = function(form) {
  if(!this.compiled) {
      this.compile();
  }
  var field = this.field(form);
  // the datetime combinedModel is already a js date obj with both date and time rolled in.
  var date;

  if (field.scope().combinedModel == undefined) {
    var d = new Date();
    date = d.toISOString();
  } else {
    date = formatDateTimeString(field.scope().combinedModel);
  }

  var newDate = moment(date).toDate();

  var c = new Array();
  c['text'] = formatDisplayDateTime(newDate);
  c['val'] = formatSearchDateTime(newDate);
  return c;
};
DateTimeBoxStrategy.prototype.reset = function(form) {
  var field = this.field(form);
  field.scope().dateModel = {};
  field.scope().timeModel = {};
};
DateTimeBoxStrategy.prototype.set = function(form, criteria) {
  if(this.compiled === false) {
    this.compile();
  }
  var field = angular.element(this.field(form));
  var year = parseInt(criteria['val'].slice(0,4));
  var month = parseInt(criteria['val'].slice(4,6)) - 1;
  var day = parseInt(criteria['val'].slice(6,8));
  var hour = parseInt(criteria['val'].slice(8,10));
  var minute = parseInt(criteria['val'].slice(10,12));
  var dt = new Date(year, month, day, hour, minute);
  field.scope().dateModel = moment(dt).format('L');
  field.scope().timeModel = moment(dt).format('LT');
  field.scope().$apply();
};
DateTimeBoxStrategy.prototype.show = function (form) {
    form.find(this.selector).show();
    if (!this.compiled) {
        this.compile();
    }
};
DateTimeBoxStrategy.prototype.validRequired = function(form) {
  var field = this.field(form);
  var val = $.trim(field.val());
  return val != undefined && val != '';
};
DateTimeBoxStrategy.prototype.validFormat = function(form) {
  var field = this.field(form);
  var val = $.trim(field.val());
  return isValidDateTime(val);
};
DateTimeBoxStrategy.prototype.valid = function(form) {
  return this.validRequired(form) && this.validFormat(form);
};
DateTimeBoxStrategy.prototype.validate = function(form, errors) {
  if (!this.validRequired(form)) {
    errors.add(form.find(this.selector + ' label').filterByAttribute('for', 'requiredErrorMessage').text());
  }
};
DateTimeBoxStrategy.prototype.compile = function() {
    var select = this.selector;
    var ng = $('#ng-expose').scope();

    ng.getCompile()(angular.element(select))(ng.getRootScope());
    ng.getRootScope().$apply();
    this.compiled = true;
};

/**
 * Strategy used to find/get/set/validate days boxes.
 * 
 * @param selector -
 *          the jquery selector used to find the div containing the input field.
 */
function DaysBoxStrategy(selector) {
  this.selector = 'li.values.' + selector;
}
DaysBoxStrategy.prototype.field = function(form) {
  return form.find(this.selector + ' input').first();
};
DaysBoxStrategy.prototype.get = function(form) {
  var field = this.field(form);
  var string = $.trim(field.val()).replace(/,*/g, '').replace(/\s*/g, '');
  var number = parseInt(string);
  var displayValueFormat = form.find(this.selector + ' label').filterByAttribute('for', 'displayValueFormat').text();
  var c = new Array();
  c['text'] = displayValueFormat.format(formatDisplayInteger(number));
  c['val'] = formatSearchInteger(number);
  return c;
};
DaysBoxStrategy.prototype.reset = function(form) {
  var field = this.field(form);
  field.val('');
};
DaysBoxStrategy.prototype.set = function(form, criteria) {
  var field = this.field(form);
  field.val(criteria['val']);
};
DaysBoxStrategy.prototype.show = function(form) {
  form.find(this.selector).show();
};
DaysBoxStrategy.prototype.validRequired = function(form) {
  var field = this.field(form);
  var val = $.trim(field.val());
  return val != undefined && val != '';
};
DaysBoxStrategy.prototype.validFormat = function(form) {
  var field = this.field(form);
  var val = $.trim(field.val());
  return isValidDays(val);
};
DaysBoxStrategy.prototype.valid = function(form) {
  return this.validRequired(form) && this.validFormat(form);
};
DaysBoxStrategy.prototype.validate = function(form, errors) {
  if (!this.validRequired(form)) {
    errors.add(form.find(this.selector + ' label').filterByAttribute('for', 'requiredErrorMessage').text());
  } else if (!this.validFormat(form)) {
    errors.add(form.find(this.selector + ' label').filterByAttribute('for', 'formatErrorMessage').text());
  }
};

/**
 * Strategy used to find/get/set/validate time boxes.
 * 
 * @param selector -
 *          the jquery selector used to find the div containing the input field.
 */
function TimeBoxStrategy(selector) {
  this.selector = 'li.values.' + selector;
}
TimeBoxStrategy.prototype.field = function(form) {
  return form.find(this.selector + ' input').first();
};
TimeBoxStrategy.prototype.get = function(form) {
  var field = this.field(form);
  var date = new Date('01/01/2000 ' + field.val());
  var c = new Array();
  c['text'] = formatDisplayTime(date);
  c['val'] = formatSearchTime(date);
  return c;
};
TimeBoxStrategy.prototype.reset = function(form) {
  var field = this.field(form);
  field.val('');
};
TimeBoxStrategy.prototype.set = function(form, criteria) {
  var field = this.field(form);
  field.val(criteria['text']);
};
TimeBoxStrategy.prototype.show = function(form) {
  // first, we need to reset the calendar popup widget (for some reason)
  form.find(this.selector + ' img').remove();
  var fresh = $('<input type="text" class="date" />');
  var stale = form.find(this.selector + ' input').first();
  fresh.val(stale.val());
  fresh.attr('title', stale.attr('title'));
  stale.before(fresh);
  stale.remove();
  fresh.timepicker(timePickerOptions);
  form.find(this.selector).show();
};
TimeBoxStrategy.prototype.validRequired = function(form) {
  var field = this.field(form);
  var val = $.trim(field.val());
  return val != undefined && val != '';
};
TimeBoxStrategy.prototype.validFormat = function(form) {
  var field = this.field(form);
  var val = $.trim(field.val());
  return isValidTime(val);
};
TimeBoxStrategy.prototype.valid = function(form) {
  return this.validRequired(form) && this.validFormat(form);
};
TimeBoxStrategy.prototype.validate = function(form, errors) {
  if (!this.validRequired(form)) {
    errors.add(form.find(this.selector + ' label').filterByAttribute('for', 'requiredErrorMessage').text());
  } else if (!this.validFormat(form)) {
    errors.add(form.find(this.selector + ' label').filterByAttribute('for', 'formatErrorMessage').text());
  }
};

/**
 * Strategy used to find/get/set/validate double boxes.
 * 
 * @param selector -
 *          the jquery selector used to find the div containing the input field.
 */
function DoubleBoxStrategy(selector) {
  this.selector = 'li.values.' + selector;
}
DoubleBoxStrategy.prototype.field = function(form) {
  return form.find(this.selector + ' input').first();
};
DoubleBoxStrategy.prototype.get = function(form) {
  var field = this.field(form);
  var string = $.trim(field.val()).replace(/,*/g, '').replace(/\s*/g, '');
  var number = parseFloat(string);
  var c = new Array();
  c['text'] = formatDisplayDouble(number);
  c['val'] = formatSearchDouble(number);
  return c;
};
DoubleBoxStrategy.prototype.reset = function(form) {
  var field = this.field(form);
  field.val('');
};
DoubleBoxStrategy.prototype.set = function(form, criteria) {
  var field = this.field(form);
  field.val(criteria['val']);
};
DoubleBoxStrategy.prototype.show = function(form) {
  form.find(this.selector).show();
};
DoubleBoxStrategy.prototype.validRequired = function(form) {
  var field = this.field(form);
  var val = $.trim(field.val());
  return val != undefined && val != '';
};
DoubleBoxStrategy.prototype.validFormat = function(form) {
  var field = this.field(form);
  var val = $.trim(field.val());
  return isValidDouble(val);
};
DoubleBoxStrategy.prototype.valid = function(form) {
  return this.validRequired(form) && this.validFormat(form);
};
DoubleBoxStrategy.prototype.validate = function(form, errors) {
  if (!this.validRequired(form)) {
    errors.add(form.find(this.selector + ' label').filterByAttribute('for', 'requiredErrorMessage').text());
  } else if (!this.validFormat(form)) {
    errors.add(form.find(this.selector + ' label').filterByAttribute('for', 'formatErrorMessage').text());
  }
};

/**
 * Strategy used to find/get/set/validate integer boxes.
 * 
 * @param selector -
 *          the jquery selector used to find the div containing the input field.
 */
function IntegerBoxStrategy(selector) {
  this.selector = 'li.values.' + selector;
}
IntegerBoxStrategy.prototype.field = function(form) {
  return form.find(this.selector + ' input').first();
};
IntegerBoxStrategy.prototype.get = function(form) {
  var field = this.field(form);
  var string = $.trim(field.val()).replace(/,*/g, '').replace(/\s*/g, '');
  var number = parseInt(string);
  var c = new Array();
  c['text'] = formatDisplayInteger(number);
  c['val'] = formatSearchInteger(number);
  return c;
};
IntegerBoxStrategy.prototype.reset = function(form) {
  var field = this.field(form);
  field.val('');
};
IntegerBoxStrategy.prototype.set = function(form, criteria) {
  var field = this.field(form);
  field.val(criteria['val']);
};
IntegerBoxStrategy.prototype.show = function(form) {
  form.find(this.selector).show();
};
IntegerBoxStrategy.prototype.validRequired = function(form) {
  var field = this.field(form);
  var val = $.trim(field.val());
  return val != undefined && val != '';
};
IntegerBoxStrategy.prototype.validFormat = function(form) {
  var field = this.field(form);
  var val = $.trim(field.val());
  return isValidInteger(val);
};
IntegerBoxStrategy.prototype.valid = function(form) {
  return this.validRequired(form) && this.validFormat(form);
};
IntegerBoxStrategy.prototype.validate = function(form, errors) {
  if (!this.validRequired(form)) {
    errors.add(form.find(this.selector + ' label').filterByAttribute('for', 'requiredErrorMessage').text());
  } else if (!this.validFormat(form)) {
    errors.add(form.find(this.selector + ' label').filterByAttribute('for', 'formatErrorMessage').text());
  }
};

/**
 * Strategy used to find/get/set/validate money boxes.
 * 
 * @param selector -
 *          the jquery selector used to find the div containing the input field.
 */
function MoneyBoxStrategy(selector, currencySymbol, currencyDecimalPlaces) {
  this.selector = 'li.values.' + selector;
  this.currencySymbol = currencySymbol;
  this.currencyDecimalPlaces = currencyDecimalPlaces;
}
MoneyBoxStrategy.prototype.field = function(form) {
  return form.find(this.selector + ' input').first();
};
MoneyBoxStrategy.prototype.get = function(form) {
  var field = this.field(form);
  var string = $.trim(field.val()).replace(/,*/g, '').replace(/\s*/g, '').replace(this.currencySymbol, '');
  var number = parseFloat(string);
  var c = new Array();
  c['text'] = formatDisplayMoney(this.currencySymbol, this.currencyDecimalPlaces, number);
  c['val'] = formatSearchMoney(this.currencySymbol, this.currencyDecimalPlaces, number);
  return c;
};
MoneyBoxStrategy.prototype.reset = function(form) {
  var field = this.field(form);
  field.val('');
};
MoneyBoxStrategy.prototype.set = function(form, criteria) {
  var field = this.field(form);
  field.val(criteria['val']);
};
MoneyBoxStrategy.prototype.show = function(form) {
  form.find(this.selector).show();
};
MoneyBoxStrategy.prototype.validRequired = function(form) {
  var field = this.field(form);
  var val = $.trim(field.val());
  return val != undefined && val != '';
};
MoneyBoxStrategy.prototype.validFormat = function(form) {
  var field = this.field(form);
  var val = $.trim(field.val());
  return isValidMoney(this.currencySymbol, this.currencyDecimalPlaces, val);
};
MoneyBoxStrategy.prototype.valid = function(form) {
  return this.validRequired(form) && this.validFormat(form);
};
MoneyBoxStrategy.prototype.validate = function(form, errors) {
  if (!this.validRequired(form)) {
    errors.add(form.find(this.selector + ' label').filterByAttribute('for', 'requiredErrorMessage').text());
  } else if (!this.validFormat(form)) {
    var message = form.find(this.selector + ' label').filterByAttribute('for', 'formatErrorMessage').text();
    errors.add(message.format(this.currencyDecimalPlaces));
  }
};

/**
 * Strategy used when no value is to be entered.
 */
function NoValueStrategy() {
}
NoValueStrategy.prototype.get = function(form) {
  var c = new Array();
  c['text'] = '';
  c['val'] = '';
  return c;
};
NoValueStrategy.prototype.reset = function(form) {
  // nothing to reset
};
NoValueStrategy.prototype.set = function(form, criteria) {
  // nothing to set
};
NoValueStrategy.prototype.show = function(form) {
  // nothing to show
};
NoValueStrategy.prototype.valid = function(form) {
  return true;
};
NoValueStrategy.prototype.validate = function(form, errors) {
  // nothing to validate
};

/**
 * Strategy used to switch strategies based on operator selection.
 * 
 * @param selector -
 *          the jquery selector used to find the div containing the operator
 * @param dStrategy -
 *          the default strategy to use when a specific strategy is not found
 *          for the selected operator
 */
function OperatorDelegateStrategy(selector, dStrategy) {
  this.selector = 'li.operators.' + selector;
  this.defaultStrategy = dStrategy;
  this.strategies = new Array();
  this.strategies['@@Exists'] = new NoValueStrategy();
  this.strategies['@@Does_Not_Exist'] = new NoValueStrategy();
}
OperatorDelegateStrategy.prototype.addStrategy = function(key,strategy) {
  this.strategies[key] = strategy;
};
OperatorDelegateStrategy.prototype.removeStrategy = function(key) {
  delete this.strategies[key];
};
OperatorDelegateStrategy.prototype.strategy = function(form) {
  var operator = form.find(this.selector + ' option:selected').first();
  var opText = operator.text();
  //Somehow whitespace is being added to the leading and trailing side of the operator
  // since it comes from the html element I'm not able to figure out where the whitespace is coming from
  // we should be trimming anyway since we are matching on strings
  opText = opText.trim();
  var strategy = this.defaultStrategy;
  if (opText in this.strategies) {
    strategy = this.strategies[opText];
  }
  return strategy;
};
OperatorDelegateStrategy.prototype.get = function(form) {
  return this.strategy(form).get(form);
};
OperatorDelegateStrategy.prototype.reset = function(form) {
  return this.strategy(form).get(form);
};
OperatorDelegateStrategy.prototype.set = function(form, criteria) {
  this.strategy(form).set(form, criteria);
};
OperatorDelegateStrategy.prototype.show = function(form) {
  this.strategy(form).show(form);
};
OperatorDelegateStrategy.prototype.valid = function(form) {
  return this.strategy(form).valid(form);
};
OperatorDelegateStrategy.prototype.validate = function(form, errors) {
  this.strategy(form).validate(form, errors);
};

/**
 * Strategy used to find/get/set/validate text boxes.
 * 
 * @param selector -
 *          the jquery selector used to find the div containing the input field.
 */
function TextBoxStrategy(selector) {
  this.selector = 'li.values.' + selector;
}
TextBoxStrategy.prototype.field = function(form) {
  return form.find(this.selector + ' input').first();
};
TextBoxStrategy.prototype.get = function(form) {
  var field = this.field(form);
  var c = new Array();
  c['text'] = field.val();
  c['val'] = field.val();
  return c;
};
TextBoxStrategy.prototype.reset = function(form) {
  var field = this.field(form);
  field.val('');
};
TextBoxStrategy.prototype.set = function(form, criteria) {
  var field = this.field(form);
  field.val(criteria['val']);
};
TextBoxStrategy.prototype.show = function(form) {
  form.find(this.selector).show();
};
TextBoxStrategy.prototype.valid = function(form) {
  var field = this.field(form);
  return $.trim(field.val()) != '';
};
TextBoxStrategy.prototype.validate = function(form, errors) {
  if (!this.valid(form)) {
    errors.add(form.find(this.selector + ' label').filterByAttribute('for', 'requiredErrorMessage').text());
  }
};
